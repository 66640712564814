function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

$(function () {
    
    let $popup_overlay = getCookie('popup_overlay');
    let $popup_dialog = getCookie('popup_dialog');

    if($popup_overlay != 'true'){
      $('.popup-overlay').show();
    }
    if($popup_dialog != 'true'){
      $('.popup-dialog').show();
    }


    new WOW().init();

    let windowsWidth = $( window ).width();

    //SMOOTH SCROLL
    $('html').smoothScroll(900);

    //MOBILE MENU
    $('.mobile-btn').bind('click', function(ev){
        ev.preventDefault();
        $('.header-mobile').toggleClass('active');
        $('body').toggleClass('menu-open');
    });

    //MENU FONDOS
    $('header ul li').hover( function(){
      $('header').removeClass('purple-menu');
      $('.menu-fondos').removeClass('show-menu-fondos');
    });
    $('header ul li.menu-fondos-open').hover( function(){
      $('header').addClass('purple-menu');
      $('.menu-fondos').addClass('show-menu-fondos');
    });
    $('.menu-fondos').mouseleave( function(){
      $('header').removeClass('purple-menu');
      $('.menu-fondos').removeClass('show-menu-fondos');
    });

    //FONDOS
    $('.accordion-button').bind('click', function(ev){
        ev.preventDefault();
        var $this = $(this);
        var $parent = $this.parent();
        if(!$parent.hasClass('open')){
          var contentHeight = 0;
          $parent.find('.fondos-list .fondos-item').each(function(){
            contentHeight = contentHeight + $(this).height();
          });
          console.log(contentHeight);
          $parent.find('.fondos-list').css('height',contentHeight);
        }else{
          $parent.find('.fondos-list').css('height',0);
        }
        $parent.toggleClass('open');
    });

    //PARTNERS
    $('.people-item .img').bind('click', function(ev){
        ev.preventDefault();
        
        $('.people-item-open .people-item-description').css('top',0);
        $('.people-item').addClass('people-item-not-open').removeClass('people-item-open').css('padding-bottom',0);
        
        const myTimeout = setTimeout(()=>{
          var $this = $(this).parent().parent();
          $this.removeClass('people-item-not-open').addClass('people-item-open');

          var thisPosition = $this.position();
          $this.find('.people-item-description').css('top',thisPosition.top+410);

          var contentHeight = $this.find('.people-item-description').height();
          $this.css('padding-bottom',contentHeight+150);

          clearTimeout(myTimeout);
        }, 300);

        
    });

    $('.people-item .close').bind('click', function(ev){
        ev.preventDefault();
        $('.people-item-open .people-item-description').css('top',0);
        $('.people-item').removeClass('people-item-open').removeClass('people-item-not-open').css('padding-bottom',0);
    });

    $('a.open-hidden-action').bind('click', function(ev){
      ev.preventDefault();
      var $this = $(this);
      $('a.open-hidden-action').removeClass('active');
      $this.addClass('active');
      var section_name = $this.data('section');
      $('.section-container .section-hidden.opened').removeClass('opened');
      $('.section-container').removeClass('open-section-hidden');
      $('.section-container .section-hidden.section-'+section_name).parent().parent().addClass('open-section-hidden');
      $('.section-container .section-hidden.section-'+section_name).addClass('opened');

    });
    $('.section-hidden .close').bind('click', function(ev){
      ev.preventDefault();
      $('a.open-hidden-action').removeClass('active');
      $('.section-container').removeClass('open-section-hidden');
      $('.section-container .section-hidden.opened').removeClass('opened');
    });

    //POPUPS
    $('.popup-overlay .close-content').bind('click', function(ev){
      ev.preventDefault();
      $('.popup-overlay').hide();
      setCookie('popup_overlay', true, 1);
    });
    $('.popup-dialog .close').bind('click', function(ev){
      ev.preventDefault();
      $('.popup-dialog').hide();
      setCookie('popup_dialog', true, 1);
    });

    //VER MAS
    $('#vermas').bind('click', function(ev){
      ev.preventDefault();
      $('.card-container .hide').removeClass('hide');
      $('#vermas').hide();
    });

    //HISTORY LINE
    var historyHeight = $('.entry').last().position();
    if(historyHeight){
      let restaHistory = (windowsWidth<769)?0:70;
      $('.entries .entries-line').css('height',historyHeight.top-restaHistory);
    }

});

$( window ).resize(function() {

    let windowsWidth = $( window ).width();

    //HISTORY LINE
    var historyHeight = $('.entry').last().position();
    if(historyHeight){
      let restaHistory = (windowsWidth<769)?0:70;
      $('.entries .entries-line').css('height',historyHeight.top-restaHistory);
    }

});


// When the user scrolls down 50px from the top of the document this changes the header's bg color.
window.onscroll = function() {scrollFunction()};
function scrollFunction() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    $('body.home header').addClass('on-scroll');
  } else {
    $('body.home header').removeClass('on-scroll');
  }
}

//HIDDING MENU ON SCROLL
const body = document.body;
const nav = document.querySelector(".page-header nav");
const menu = document.querySelector(".page-header .menu");
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

window.addEventListener("scroll", () => {
  const currentScroll = window.pageYOffset;
  if (currentScroll <= 0) {
    body.classList.remove(scrollUp);
    return;
  }

  if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
    // down
    body.classList.remove(scrollUp);
    body.classList.add(scrollDown);
  } else if (
    currentScroll < lastScroll &&
    body.classList.contains(scrollDown)
  ) {
    // up
    body.classList.remove(scrollDown);
    body.classList.add(scrollUp);
  }
  lastScroll = currentScroll;
});